import React, { useState } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import Divider from "decentraland-gatsby/dist/components/Text/Divider"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import Intercom from "../components/Intercom/Intercom"
import Layout from "../components/Layout/Layout"
import FAQSection from "../components/Support/FAQSection"
import HelpCenterSection from "../components/Support/HelpCenterSection"
import StatusDropdown from "../components/Support/StatusDropdown"
import SupportUpdatesSection from "../components/Support/SupportUpdatesSection"
import backgroundDclImage from "../images/background-v3@1x.jpg"

import "./help.css"

const INTERCOM_APP_ID = "z0h94kay"

const SERVICES: { name: string; url: string }[] = [
  { name: "Catalysts", url: "https://peer-ec1.decentraland.org/about" },
  { name: "Catalysts", url: "https://interconnected.online/about" },
  { name: "Catalysts", url: "https://peer.decentral.io/about" },
  { name: "Catalysts", url: "https://peer.melonwave.com/about" },
  { name: "Catalysts", url: "https://peer.kyllian.me/about" },
  { name: "Catalysts", url: "https://peer.uadevops.com/about" },
  { name: "Catalysts", url: "https://peer.dclnodes.io/about" },
  { name: "Catalysts", url: "https://peer-ap1.decentraland.org/about" },
  { name: "Catalysts", url: "https://peer-eu1.decentraland.org/about" },
  { name: "Marketplace", url: "https://peer-ec2.decentraland.org/about" },
  { name: "Chat", url: "https://peer-wc1.decentraland.org/about" },
  { name: "Builder", url: "https://builder-api.decentraland.org/v1/info" },
  { name: "Places", url: "https://places.decentraland.org/api/status" },
  { name: "Events", url: "https://events.decentraland.org/api/status" },
]

export enum HelpTab {
  FAQ = "faq",
  SUPPORT_UPDATES = "support updates",
}

export default function SupportPage(props: any) {
  const l = useFormatMessage()
  const [activeTab, setActiveTab] = useState(HelpTab.FAQ)

  return (
    <Layout {...props} isFullscreen isOverlay hideFooter={false}>
      <Head
        title={(l("page.support.title"), l("component.landing.help.title"))}
        description={l("page.support.description") || ""}
        image={backgroundDclImage}
      />
      <div className="support">
        <HelpCenterSection
          activeTab={activeTab}
          setTab={setActiveTab}
          serviceList={SERVICES}
        />
        <FAQSection isActive={activeTab === HelpTab.FAQ} />
        <SupportUpdatesSection
          isActive={activeTab === HelpTab.SUPPORT_UPDATES}
        />
        <StatusDropdown
          serviceList={SERVICES}
          className="status-dropdown--desktop"
        />
      </div>
      {typeof window !== "undefined" ? (
        <Intercom appId={INTERCOM_APP_ID} settings={{ alignment: "right" }} />
      ) : null}
      <Divider size="tiny" color="rgba(115, 110, 125, 0.68)" />
    </Layout>
  )
}
